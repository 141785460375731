%side-item {
  padding-left: $indent-standart;
  padding-right: $indent-standart;
}

%side {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  background-color: $white;
}

%flex-column {
  display: flex;
  flex-direction: column;
  min-height: 0%;
}

%flex-stretched {
  flex: 1 1;
  min-height: 0%;
}

.side {
  @extend %flex-stretched;
  transition: $transition;
}

.side-content {
  @extend %flex-column;
  @extend %flex-stretched;
}

.side-inner {
  @extend %flex-column;
  @extend %flex-stretched;
  @extend %side;
  position: fixed;
  width: 246px;
  top: 93px;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  transition: $transition;
  padding: 10px 0;
  z-index: 10;
  box-shadow: $shadow-medium;

  .side.is-open & {
    transform: translateX(0);
  }
}

.side-logo {
  @extend %side-item;
}

.side-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $form-control-height;
  padding: 0 $control-horizontal-indent;
  background: $disabled-border-color;
  border-radius: $border-radius;
  font-weight: 600;
  text-transform: uppercase;
  min-width: 160px;
  cursor: pointer;
  transition: $transition;
  &.disabled {
    cursor: default;
    pointer-events: none;
  }

  &:not(.disabled):hover {
    color: $tomato;
  }
}
