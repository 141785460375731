.nowtime {
  position: absolute;
  top: 0;
  left: 74px;
  width: calc(100% - 74px);
  height: 1px;
  border-top: 1px solid $tomato;
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: -5px;
    transform: translateX(-100%);
    width: 9px;
    height: 9px;
    background-color: $tomato;
    border-radius: 100%;
  }
}
