
.service-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    background-color: #f4f6f9; 
    text-align: center; 
    padding: 20px; 
  }
  
  .service-content {
    max-width: 600px; 
    padding: 30px;
    background-color: #ffffff; 
    border-radius: 8px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
  }
  
  .service-title {
    font-size: 36px; 
    margin-bottom: 20px;
    color: #d52b1e; 
  }
  
  .service-description {
    font-size: 18px;
    color: #111; 
    line-height: 1.6;
  }