.modal-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, .8);
  z-index: 100;
  cursor: pointer;
}

.modal-container {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 460px;
  max-height: 100vh;
  border-radius: 3px;
  box-shadow: $shadow-large;
  background-color: white;
  line-height: 1;
  z-index: 101;
  overflow: hidden;

  &.right {
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 450px;
  }

  &.left {
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    max-width: 450px;
  }

  &.large {
    max-width: 490px;
  }

  .auth & {
    box-shadow: $shadow-large-light;
    overflow: visible;
  }

  &.messages {
    max-width: 70%;
    min-width: 460px;
    min-height: 80vh;
  }
}

.modal-header {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
}

.modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  color: $black;
}

.modal-subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
}

.modal-body {
  background-color: $white;
  overflow: auto;
  flex: 1 1 auto;
  min-height: 0%;

  &.modal-flex-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.modal-content {
  padding: 24px;
  background-color: $light-grey;

  &.light {
    background-color: $white;
  }
}

.modal-section {
  background-color: $light-grey;

  & + & {
    margin-top: 20px;
  }
}

.modal-label {
  margin: 0 15px 0 0;
}

.modal-close {
  position: absolute;
  left: 0;
  top: 0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  box-shadow: none;
  border: none;
  cursor: pointer;
  transition: $transition;

  &:hover {
    color: $tomato;
  }
}

.modal-close-right {
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  box-shadow: none;
  border: none;
  cursor: pointer;
  transition: $transition;

  &:hover {
    color: $tomato;
  }
}

.modal-additional-icon {
  position: absolute;
  left: 21px;
  background: transparent;
  box-shadow: none;
  border: none;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }
}

.modal-footer {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: $light-grey;

  &.light {
    background-color: $white;
  }
}

.modal-msg {
  margin: 0 auto;
  max-width: 364px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.31;
  & + & {
    margin-top: 15px;
  }
}

.modal-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.31;
  height: 20px;

  &.thin {
    font-size: 14px;
    font-weight: 400;
  }
}