.number-input {
  @extend %text-control;
  padding-right: 30px;
}

.number-controls {
  position: absolute;
  right: 1px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 18px;
  border-left: 1px solid $pinkish-grey;
  color: $steel;

  .number-input:disabled ~ & {
    pointer-events: none;
    cursor: default;
  }

  .number-input:disabled ~ & {
    border-color: $disabled-border-color;
  }

  .number-input:not(:disabled):focus ~ &,
  .number-input:not(:disabled):hover ~ & {
    border-color: $steel;
  }

  .number-input.has-error ~ &,
  .form-control.has-error & {
    border-color: $tomato;
  }
}

.number-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  background: transparent;
  border: 0;
  cursor: pointer;
  &:not(:first-child) {
    border-top: 1px solid $pinkish-grey;
  }

  &:disabled {
    cursor: default;
    opacity: .5;
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: $tomato;
  }

  .number-input:not(:disabled):focus ~ .number-controls &,
  .number-input:not(:disabled):hover ~ .number-controls & {
    border-color: $steel;
  }

  .number-input:disabled ~ .number-controls & {
    pointer-events: none;
    cursor: default;
    border-color: $disabled-border-color;
  }

  .number-input.has-error ~ .number-controls &,
  .form-control.has-error & {
    border-color: $tomato;
  }
}
