.form-control {
  @extend %form-control;
}

.form-field {
  position: relative;

  &.xxs {
    width: 64px;
  }

  &.xs {
    width: 72px;
  }

  &.sm {
    width: 92px;
  }

  &.md {
    width: 104px;
  }

  &.lg {
    width: 134px;
  }

  &.xl {
    width: 212px;
  }

  &.xxl {
    width: 266px;
  }

  &.flat {
    height: 100%;
    //width: 122px;
  }
}

.form-group {
  position: relative;
  & + & {
    margin-top: 20px;
  }
}

.form-group-vertical {
  position: relative;
  & + & {
    margin-left: 20px;
  }

  &.message-fields {
    flex: 1 1 50%;
    min-height: 100%;
  }

  &.recipients-fields {
    flex: 1 1 50%;
  }
}

.form-text {
  margin: auto 0;

  &.title-italic {
    font-style: italic;
    font-weight: 500;
  }

  &.title-bold {
    font-weight: 600;
    font-size: medium;
  }
}


.form-label {
  display: block;
  margin-bottom: 8px;
  white-space: nowrap;
  line-height: 1.14;
  .auth & {
    padding: 0 $control-horizontal-indent;
  }
}

.form-error {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  display: none;
  padding: 3px 0;
  font-size: 12px;
  line-height: 1.17;
  color: $tomato;

  .form-control.has-error & {
    display: block;
  }

  .auth & {
    padding: 3px $control-horizontal-indent;
  }
}

.form-login-error{
  margin-top: 20px;
  position: absolute;
  bottom: 20px;
  font-weight: 500;
  color: $tomato;
}

.form-validation {
  @extend .form-error;
  &:not(:empty) {
    display: block;
  }
}

.form-tags  {
  margin-top: 12px;
}

.form-info {
  color: $cloudy-blue;
  font-weight: bold;
}
