.details {
  margin-top: 20px;
}

.details-item {
  display: flex;
  align-items: center;
}

.details-label {
  flex: 1 1 auto;
  min-width: 0%;
}

.details-value {
  flex: 0 0 auto;
  width: 258px;
  margin-left: 24px;
  &__email{
    word-wrap:break-word;
    line-height: 25px;
  }
}

.details-value:has(.icon-copy){
  display: flex;
  align-items: center;
    .icon-copy{
      margin-left: 10px;
    }
}