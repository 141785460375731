.Toastify__toast {
  .Toastify & {
    border-radius: $border-radius;
    box-shadow: $shadow;
    font-family: inherit;
    font-weight: 500;
  }
}

.Toastify__toast-body {
  .Toastify & {
    padding: 0 10px;
  }
}

.Toastify__close-button {
  .Toastify & svg path {
    fill: $white;
  }
}

.Toastify__toast--success {
  .Toastify & {
    background-color: $green-blue;
  }
}

.Toastify__toast--warning {
  .Toastify & {
    background-color: $yellow-orange;
  }
}

.Toastify__toast--error {
  .Toastify & {
    background-color: $tomato;
  }
}
