.calendar {
  flex: 1 1 auto;
  min-height: 0%;
  display: flex;
  flex-direction: column;
  background: $white;
  box-shadow: $shadow;
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: 15px 20px 12px;
  border-bottom: 1px solid $disabled-border-color;
}

.calendar-subheader {
  flex: 0 0 auto;
  border-bottom: 1px solid $disabled-border-color;
  text-align: center;
}

.calendar-row {
  flex: 1 1 auto;
  min-height: 0%;
  display: flex;
  &:not(:last-child) {
    border-bottom: 1px solid $disabled-border-color;
  }
}

.calendar-week {
  width: 25px;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}

.week-label {
  width: 25px;
  height: 25px;
  border-radius: 15%;
  background-color: $grey;
  text-align: center;
}

.calendar-cell {
  padding: 7px 12px;
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
  font-size: 13px;
  font-weight: 600;
  line-height: 1.15;
  &:not(:nth-child(2)) {
    border-left: 1px solid $disabled-border-color;
  }
  &.disabled {
    color: $pinkish-grey;
    pointer-events: none;
  }
  .calendar-body & {
    cursor: pointer;
  }
  &.selected,
  &:hover {
    box-shadow: $shadow-large-light;
  }
}

.calendar-body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0%;
}

.calendar-title {
  flex: 1 1 auto;
  min-width: 0%;
  text-align: center;
  color: $steel;
}

.calendar-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  cursor: pointer;
  box-shadow: none;
}

.calendar-day {
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.17;
  color: $black;
}


.calendar-number {
  display: block;
  margin-bottom: 1px;

  .calendar-cell.selected &,
  .calendar-cell:hover & {
    color: $tomato;
  }
}
