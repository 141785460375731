.table {
  display: flex;
  flex-direction: column;
  box-shadow: $shadow;
  background: $white;
  font-size: 13px;
  flex: 1 1 auto;
  min-height: 0%;
  transform: translate3d(0, 0, 0);

  .ps--active-x > .ps__rail-x,
  .ps--active-y > .ps__rail-y {
    position: fixed;
    z-index: 2;
    left: 0 !important;
    right: 0 !important;
  }

  .ps--active-y > .ps__rail-y {
    right: 0 !important;
    left: auto !important;
    top: auto !important;
    bottom: 0 !important;
    transform: translateX(100%);
  }

  .ps--active-x > .ps__rail-x {
    bottom: 0 !important;
    top: auto !important;
    transform: translateY(100%);
  }

  .cell-text {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.big-text {
      overflow: hidden;
      white-space: pre;
    }
  }
}

.table-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0%;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  overflow: hidden;
}

.table-header {
  display: block;
  position: relative;
  box-shadow: $shadow;
  width: 100%;
  flex: 0 0 auto;
  z-index: 1;
}

.table-body {
  display: block;
  overflow: hidden;
  width: 100%;
  flex: 1 1 auto;
  min-height: 0%;
}

.table-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  grid-auto-flow: column;
  background-color: $white;

  &.table-users {
    grid-template-columns: repeat(2, minmax(100px, 1.5fr)) minmax(50px, 1fr) repeat(4, minmax(100px, 1fr)) repeat(2, minmax(50px, 1fr)) minmax(100px, 1fr);
  }

  &.table-msgs {
    grid-template-columns: repeat(4, minmax(100px, 1fr)) minmax(200px, 2fr);
  }

  &.table-admin-msgs {
    grid-template-columns: minmax(50px, 0.5fr) repeat(2, minmax(100px, 1fr)) minmax(200px, 2fr) repeat(2, minmax(50px, 0.5fr));
  }

  &.table-coupling {
    grid-template-columns: repeat(2, minmax(200px, 2fr)) minmax(100px, 0.5fr);
  }
}

.table-cell {
  display: flex;
  min-height: 43px;
  max-height: 83px;
  line-height: 23px;
  padding: 10px 20px;
  text-align: left;
  min-width: 200px;
  background-color: $white;

  .table-header & {
    display: flex;
    align-items: center;
    height: 52px;
    line-height: 32px;
    background-color: $white;
  }

  .table-body .table-row:nth-child(odd) & {
    background-color: $light-grey;
  }
  .table-body .table-row:hover & {
    cursor: pointer;
    background-color: $light-peach;
  }

  .table-body .active-row.last-row & {
    background-color: $light-peach;
  }
}

.table-cell-content {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  .table-cell-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // &:first-letter {
    //   text-transform: capitalize;
    // }
  }
  
  .table-arrow {
    display: block;
    flex: 0 0 auto;
    width: 5px;
    height: 3px;
    margin-left: 8px;
    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3px 2.5px 0 2.5px;
      border-color: $steel transparent transparent transparent;
      transition: $transition;
    }
  
    .asc &:after {
      transform: rotate(180deg);
    }
  }
}