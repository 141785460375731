.header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 63px;
  z-index: 1;
  flex: 0 0 auto;
  display: flex;
  background-color: $white;
  box-shadow: $shadow;
}

.header-logo {
  @extend %side;
  @extend %side-item;
  height: calc(100% - 5px);
  align-items: flex-start;
  margin-top: 5px;
}

.header-content {
  flex: 1 1 auto;
  min-width: 0%;
}

.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0%;
}
