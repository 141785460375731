$white: #fff;
$light-grey: #f8f8f8;
$tomato: #d52b1e;
$vermillion: #de1b0d;
$rusty-red: #af1a0f;
$sky-blue: #52b5ff;
$green-blue: #00b58c;
$yellow-orange: #ffbb00;
$black: #111;
$grey: #e8eaef;
$pale-grey: #eef0f6;
$steel: #8d8d9e;
$pinkish-grey: #ccc;
$cloudy-blue: #cecedb;
$very-light-pink: #ffeceb;
$pale-salmon: #ffaba8;
$light-peach: #ffcdcb;
$pale: #fef9cc;
$cool-grey: #b2b2c2;
$pale-blue: #e6f2fe;
$light-blue-grey: #e4f8f8;

$transparent-grey: #04041496;

$disabled-border-color: #e9e9e9;
