*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: 'Work Sans', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.14;
  color: $black;
}

ul {
  list-style: none;
}

*,
*:active,
*:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.tether-element {
  z-index: 100;
}
