.menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  margin: 0;
  transition: opacity $transition;
  background: $white;
  box-shadow: $shadow;
  border: 1px solid $pinkish-grey;;
  border-radius: $border-radius;
  z-index: 10;
  opacity: 0;

  &.open {
    height: auto;
    opacity: 1;
  }
}

.menu-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(0);
  transition: $transition;

  .menu.open & {
    transform: translateY(-50%) rotate(180deg);
  }
}

.menu-list {
  padding: 7px 0;
}

.menu-link {
  display: block;
  height: 28px;
  line-height: 28px;
  padding: 0 17px;
  font-weight: 600;
  cursor: pointer;
  transition: $transition;

  &:hover,
  &.active {
    background-color: $pale-grey;
  }

  &.active {
    cursor: default;
  }
}

.menu-link.disabled {
  background-color: $pale-grey;
  cursor: default;
  pointer-events: none;
}

.menu-link.red {
  color: $tomato;
}
