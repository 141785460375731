.pagination {
  height: 40px;
  display: flex;
  align-items: center;
  user-select: none;
  transition: $transition;

  .pagination__title {
    color: $black;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-right: 12px;
  }
  .pagination__count-select {
    margin-right: 12px;
  }
}

  
.page-select {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  background: $white;
  border: solid 1px $pinkish-grey;
  border-radius: 7px;

  .page-select__markers-container {
    display: flex;
    align-items: center;
  }
  .page-select__button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0;
    border: none;
    background-color: transparent;

    &:hover {
      color: $tomato;
    }
  }
  .page-select__button--diabled {
    color: $steel;
    &:hover {
      color: $steel;
    }
  }
}

  
.page-select__marker {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  color: $black;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  background-color: transparent;
  margin-left: 9px;
  padding: 0;
  border: none;

  &:first-child {
    margin-left: 8px;
  }
  &:last-child {
    margin-right: 16px;
  }
  &:hover {
    background-color: $pale-grey;
    color: $black;
  }
}

.page-select__marker--disabled:hover {
  background-color: transparent;
  color: $black;
}
.page-select__marker--active {
  background-color: $light-peach;
  color: $black;
}
.page-select__marker--active:hover {
  background-color: $light-peach;
  color: $black;
}
  