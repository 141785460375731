.timetable {
  flex: 1 1 auto;
  min-height: 0%;
  display: flex;
  flex-direction: column;
}

.timetable-content {
  flex: 1 1 auto;
  min-height: 0%;
  display: flex;
  flex-direction: column;
  background: $white;
  box-shadow: $shadow;
}

.timetable-content-inner {
  flex: 1 1 auto;
  min-height: 0%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.timetable-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: 15px 20px 12px;
  border-bottom: 1px solid $disabled-border-color;
}

.timetable-subheader {
  display: flex;
  flex: 0 0 auto;
  border-bottom: 1px solid $disabled-border-color;
  overflow: hidden;
  box-shadow: $shadow;
}

.timetable-index {
  padding: 0 20px;
  flex: 0 0 $calendar-col-width;
  width: $calendar-col-width;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 34px;
  &:not(:first-child) {
    border-left: 1px solid $disabled-border-color;
  }
}

.timetable-body {
  flex: 1 1 auto;
  min-height: 0%;
}

.timetable-title {
  flex: 1 1 auto;
  min-width: 0%;
  text-align: center;
  color: $steel;
}

.timetable-day {
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.17;
  color: $black;
}
