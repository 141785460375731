.schedule {
  flex: 1 1 auto;
  min-height: 0%;
  display: flex;
  flex-direction: column;
  margin-bottom: -20px;
  margin-right: -20px;
  padding-bottom: 20px;
  padding-right: 20px;
  overflow: hidden;
}

.schedule-content {
  flex: 1 1 auto;
  min-height: 0%;
  display: flex;
  flex-direction: column;
  background: $white;
  box-shadow: $shadow;
  //overflow: auto;
}

.schedule-content-inner {
  flex: 1 1 auto;
  min-height: 0%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.schedule-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: 15px 20px 12px;
  border-bottom: 1px solid $disabled-border-color;
  background: $white;
  z-index: 2;
}

.schedule-control {
  flex: 0 0 226px;
  height: 100%;
  display: flex;
  align-items: center;
  &:last-child {
    justify-content: flex-end;
  }
}

.schedule-subheader {
  display: flex;
  flex: 0 0 auto;
  border-bottom: 1px solid $disabled-border-color;
  overflow: hidden;
  box-shadow: $shadow;
  background: $white;
  z-index: 2;
}

.schedule-index {
  padding: 0 20px;
  flex: 0 0 $calendar-col-width;
  width: $calendar-col-width;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  
  &:hover {
    box-shadow: $shadow-medium;
    background: $light-grey;
  }

  &:not(:last-child) {
    border-right: 1px solid $disabled-border-color;
  }

  &.schedule-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 0 0 auto;
    width: 83px;
    background: $white;
    z-index: 1;
    cursor: auto;

    &:hover {
      box-shadow: none;
    }
  }
}

.schedule-resources {
  position: relative;
  flex: 1 1 auto;
  min-width: 0%;
}

.schedule-resources-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;
}

.schedule-row {
  flex: 1 1 auto;
  min-height: 0%;
  display: flex;

  &:not(:last-child) {
    border-bottom: 1px solid $disabled-border-color;
  }
}

.schedule-col {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  flex: 0 0 $calendar-col-width;
  width: $calendar-col-width;
  font-size: 12px;
  line-height: 1.17;
}

.schedule-body {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  min-height: 0%;
  //overflow: hidden;

  .ps--active-x > .ps__rail-x,
  .ps--active-y > .ps__rail-y {
    position: fixed;
    z-index: 2;
    left: 0 !important;
    right: 0 !important;
  }

  .ps--active-y > .ps__rail-y {
    right: 0 !important;
    left: auto !important;
    top: 0 !important;
    bottom: 0 !important;
    transform: translateX(100%);
  }

  .ps--active-x > .ps__rail-x {
    bottom: 0 !important;
    top: auto !important;
    transform: translateY(100%);
  }
}

.schedule-scrollable {
  flex: 1 1 auto;
  min-width: 0%;
  display: flex;
  flex-direction: column;
  padding-top: $calendar-vertical-indent;
}


.schedule-body-inner {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0%;
  min-width: 0%;
  height: 100%;
  z-index: 1;
  transform: translate3d(0, 0, 0);
}

.schedule-section {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0%;
  transform: translate3d(0, 0, 0);
}

.schedule-scale {
  position: relative;
  flex: 0 0 auto;
  width: 83px;
  background-color: #fafcfe;
  border-right: 1px solid $disabled-border-color;
  overflow: hidden;
}

.schedule-scale-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: $calendar-vertical-indent;
}

.schedule-slots {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: $calendar-vertical-indent;
}

.schedule-body-scale {
  //padding: $calendar-vertical-indent 0;
}

.schedule-scale-row {
  height: $scale-height;
  position: relative;
  text-align: center;
  display: flex;

  .calendar .schedule-scale &:nth-child(2n - 1):after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 100%;
    border-top: 1px solid $pinkish-grey;
  }

  .schedule-body-scale & {
    width:  $calendar-width;
    border-top: 1px dashed $pinkish-grey;
    z-index: 1;
  }
}

.schedule-scale-cell {
  margin: 0 12px;
  width: calc(var(--calendar-col-width) - 24px);
  height: $scale-height;
  cursor: pointer;
  transition: $transition;
  border-radius: $border-radius;
  &:hover {
    box-shadow: $shadow-medium;
  }
}

.schedule-scale-time {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  transform: translateY(-50%);
}

.schedule-title {
  flex: 1 1 auto;
  min-width: 0%;
  text-align: center;
  color: $steel;
}


.schedule-day {
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.17;
  color: $black;
}
