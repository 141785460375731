.auth {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;

  &.main {
    min-height: auto;
    align-items: center;
  }
}

.auth-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 354px;
  align-items: center;
  padding: 240px 0 40px;
  .auth.main & {
    justify-content: center;
    padding: 0;
  }
}

.auth-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 40px 40px 50px;

  &.forgot-password {
    min-height: 308px;
  }
  &.two-factor {
    min-height: 345px;
  }
  &.select-bloodbank {
    min-height: 400px;
    justify-content: space-between;
  }
}

.auth-body {
  width: 100%;
  max-width: 257px;
}

.auth-footer {
  margin-top: 40px;
}

.auth-subinfo {
  position: relative;
  z-index: 101;
  margin-top: 12px;
  color: $steel;
}

.auth-banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 280px;
  background-image: url(./../../assets/img/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.auth-media {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.auth-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.auth-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

%auth-title {
  max-width: 272px;
  font-weight: 500;
  text-align: center;
}

.auth-title {
  @extend %auth-title;
  margin-bottom: 28px;
  font-size: 23px;
  line-height: 1.17;
}

.auth-title-2 {
  @extend %auth-title;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 1.17;

  .forgot-password & {
    flex: 1 1;
  }
}

.auth-success-msg {
  margin-top: 10px;
  margin-bottom: 0;
}
