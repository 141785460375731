.timeslot-details {
  & + & {
    margin-top: 30px;
  }
}

.timeslot-details-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.timeslot-details-title {
  flex: 1 1;
  min-width: 0%;
  margin-right: 15px;
}

.timeslot-details-controls {
  display: flex;
  align-items: center;
  margin: 0 -9px;
}

.timeslot-details-control {
  position: relative;
  padding: 0 9px;

  &:not(:first-child):before {
    content: '';
    display: block;
    width: 1px;
    height: 19px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: $cloudy-blue;
  }
}

.timeslot-details-summary {
  font-weight: 600;
  line-height: 1.14;
  & + & {
    margin-top: 15px;
  }
}

.timeslot-details-amount {
  font-weight: 600;
  font-style: italic;
  line-height: 1.14;
}
