.filters {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
}

.filters-item {
  margin-right: 20px;

  &:last-child {
    margin-left: auto;
    margin-right: 0;
  }
}
