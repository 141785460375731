.visitors {
  padding: 7px 0 17px;
  font-size: 15px;
  line-height: 1.2;
  color: $steel;
}

.visitors-btn {
  @extend %side-item;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  transition: $transition;

  &:hover,
  &:focus {
    color: $tomato;
  }
}
