.empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.17;
}

.empty-img {
  display: block;
  margin-bottom: 15px;
}
