.datepicker-control {
  @extend %text-control;
  display: flex;
  padding: 0;
}

.datepicker-value {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  min-width: 0%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px;
  border-width: 0 1px 0 1px;
  border-style: solid;
  border-color: $pinkish-grey;
  cursor: pointer;

  .datepicker-control.disabled & {
    pointer-events: none;
    cursor: default;
    border-color: $disabled-border-color;
  }

  .datepicker-control:not(.disabled):focus &,
  .datepicker-control:not(.disabled):hover & {
    border-color: $steel;
  }

  .datepicker.has-error &,
  .form-control.has-error & {
    border-color: $tomato;
  }
}

.datepicker-date {
  white-space: nowrap;
  margin-left: 10px;
  line-height: .7;
  font-size: 13px;
}

.datepicker-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: 0;
  background: transparent;
  cursor: pointer;
  box-shadow: none;
  &:first-child {
    padding-right: 3px;
  }
  &:last-child {
    padding-left: 3px;
  }
}

.datepicker-toggler {
  display: flex;
  width: 100%;
}

//MENU

.datepicker-menu {
  width: auto;
  padding: $indent-standart;
  margin: 12px 0;
  .tether-element-attached-top & {
    bottom: $form-control-height;
    top: auto;
  }
}

.picker-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -8px -5px;
}

.picker-value {
  font-weight: 600;
  line-height: 1.14;
  letter-spacing: 0.23px;
  color: $black;
  text-transform: uppercase;
}

.picker-weekdays {
  letter-spacing: .47px;
  text-transform: uppercase;
  color: $steel;
  margin: 22px 0 10px;
}

.picker-body {
  margin: -8px 0;
}

.picker-row {
  display: flex;
  align-items: center;
}

.picker-week {
  width: 25px;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}

.picker-week-label {
  width: 25px;
  height: 25px;
  border-radius: 15%;
  background-color: $grey;
  text-align: center;
}


.picker-cell {
  flex: 1 1 auto;
  padding: 5px;
  margin: 0 2px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  transition: color $transition-speed, background-color $transition-speed;

  .picker-body & {
    cursor: pointer;
    &:hover:not(.selected):not(.disabled) {
      color: $tomato;
    }
    &.selected {
      background-color: $tomato;
      color: $white;
      cursor: default;
      pointer-events: none;
    }
    &.disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.2;
    }
    &.hidden {
      cursor: default;
      pointer-events: none;
      opacity: 0;
    }
    &.today{
      cursor: pointer;
      pointer-events: auto;
      border: 1px solid $tomato;
    }
  }
}
