.page-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0%;
  background-color: #f8fafb;
  padding: 23px 0;
  overflow: auto;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0%;
}

.page-content {
  display: flex;
  flex: 1 1 auto;
  min-width: 0%;
  overflow: auto;
  transform: translate3d(0, 0, 0);
}

.container {
  width: 100%;
  margin: 0 auto;
  padding-right: $indent-standart;
  padding-left: $indent-standart;
}

.container-fluid {
  @extend .container;
  max-width: 100%;
}

.sub-header {
  flex: 0 0 auto;
  width: 100%;
  height: 63px;
  display: flex;
  justify-content: flex-end;
}