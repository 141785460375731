.nav {
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;
  min-width: 0%;
  height: 100%;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;

  &.sub-nav {
    flex: 0 0 auto;
  }
}

.nav-list {
  display: flex;
  height: 100%;
}

.nav-item {
  display: flex;
  align-items: stretch;
  height: 100%;
  &:last-child {
    flex: 1 1 auto;
    justify-content: flex-end;
  }
}

.nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  padding: 0 $indent-standart;
  color: inherit;
  cursor: pointer;
  text-transform: uppercase;

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
    display: block;
    width: 41px;
    max-width: 0;
    height: 2px;
    border-radius: 1px;
    background-color: $tomato;
    opacity: 0;
    transition: $transition;
  }

  &:hover:before,
  &.is-active:before {
    max-width: 41px;
    opacity: 1;
  }

  &.is-active {
    color: $tomato;
  }

  &.nav-disabled {
    background-color: transparent;
    transition: none;

    &:before {
      background-color: transparent;
      transition: none;
    }
  }

  .nav-controls & {
    padding: 0 20px;
  }
}
