.react-tel-input {
  .form-field & {
    font: inherit;
  }
}

//INPUT

.react-tel-input .input {
  .form-field & {
    @extend %text-control;
    width: 100%;
    padding-left: 56px;
    border-radius: $border-radius-big;
  }
}

.react-tel-input .form-control:focus,
.react-tel-input .form-control.open {
  .form-field & {
    @extend %text-control;
    border-color: $steel;
    padding-left: 56px;
    border-radius: $border-radius-big;
  }
}

.react-tel-input .form-control:not(:disabled):not(.disabled):focus.invalid-number,
.react-tel-input .form-control:not(:disabled):not(.disabled).open.invalid-number,
.react-tel-input .form-control.invalid-number {
  .form-field & {
    border-color: $tomato;
    box-shadow: none;
  }
}

//FLAG

.react-tel-input .selected-flag .flag {
  .form-field & {
    margin-top: -2px;
    transform: translateY(-50%);
    pointer-events: all;
  }

}

.react-tel-input .selected-flag .arrow {
  .form-field & {
    @extend .icon-arrow-bottom;
    pointer-events: none;
    left: 25px;
    top: 4px;
    border: 0;

    &.up {
      @extend .icon-arrow-top;
      border: 0;
    }
  }
}

//DROPDOWN

.react-tel-input .flag-dropdown {
  .form-field & {
    width: 100%;
    pointer-events: none;
  }
}

.react-tel-input .country-list {
  .form-field & {
    @extend %form-control;
    @extend .menu, .open;
    background: $white;
    box-shadow: $shadow;
    border: 1px solid $pinkish-grey;;
    border-radius: $border-radius;
    top: 0;
    pointer-events: all;
  }
}

.react-tel-input .country-list .country {
  .form-field & {
    @extend .menu-link;
    display: flex;
    padding-left: 56px;
  }
}

.react-tel-input .country-list .country-name {
  .form-field & {
    flex: 1 1 auto;
    min-width: 0%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.react-tel-input .country-list .flag {
  .form-field & {
    top: 2px;
    margin-top: 0;
  }
}

//SEARCH
.react-tel-input .country-list .search {
  .form-field & {
    @extend %form-control;
    position: sticky;
    padding: 10px;
    border-radius: 0;
    border: 0;
  }
}

::-webkit-search-cancel-button {
  @extend .icon-close;
  -webkit-appearance: none;
  cursor: pointer;

  &:hover {
    @extend .icon-close-active;
  }
}

.react-tel-input .country-list .search-box {
  .form-field & {
    @extend %text-control;
    margin-left: 0;
    border-radius: $border-radius-big;
  }
}
