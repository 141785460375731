.inputs-group {
  display: flex;
  align-items: center;
}

.inputs-group-item {
  white-space: nowrap;
  & + & {
    margin-left: 15px;
  }
}
