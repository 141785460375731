.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

@media(min-width: 0px) {
  @include col-width(null);
  @include col-offset(null);
}

@media(min-width: $breakpoint-xxs) {
  @include col-width(xxs);
  @include col-offset(xxs);
}

@media(min-width: $breakpoint-xs) {
  @include col-width(xs);
  @include col-offset(xs);
}

@media(min-width: $breakpoint-sm) {
  @include col-width(sm);
  @include col-offset(sm);
}

@media(min-width: $breakpoint-md) {
  @include col-width(md);
  @include col-offset(md);
}

@media(min-width: $breakpoint-lg) {
  @include col-width(lg);
  @include col-offset(lg);
}
